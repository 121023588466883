var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "introduce" },
    [
      _vm.showLoading
        ? _c("van-loading", {
            staticStyle: { "text-align": "center" },
            attrs: { size: "18", color: "#1989fa" }
          })
        : [
            _c("h4", [_vm._v(_vm._s(_vm.data.arrangeName))]),
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("题目数量")]),
                _c("p", [
                  _c("em", [_vm._v(_vm._s(_vm.data.totalQuestionQty))]),
                  _vm._v("道 ")
                ])
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v(_vm._s(_vm.examTimeText))]),
                _c("p", [_c("em", [_vm._v(_vm._s(_vm.examTime))])])
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("卷面总分")]),
                _c("p", [
                  _c("em", [
                    _vm._v(
                      _vm._s(_vm.data.maxScore && _vm.data.maxScore.toFixed(1))
                    )
                  ]),
                  _vm._v("分 ")
                ])
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("考试次数")]),
                _c("p", [_c("em", [_vm._v(_vm._s(_vm.useRate))])])
              ])
            ]),
            _c("ul", { staticClass: "info" }, [
              _c("li", [
                _c("h5", [_vm._v("考试说明：")]),
                _c("p", [_vm._v(" " + _vm._s(_vm.data.description) + " ")])
              ]),
              _c("li", [
                _c("h5", [_vm._v("考试目标：")]),
                _c("p", [_vm._v(" " + _vm._s(_vm.data.examGoal) + " ")])
              ]),
              _c("li", [
                _c("h5", [_vm._v("查看结果：")]),
                _c("p", [
                  _vm.data.viewStartTime && _vm.data.isAllowViewResult === 1
                    ? _c("span", [_vm._v(_vm._s(_vm.data.viewStartTime))])
                    : _vm._e(),
                  _vm.data.viewStartTime &&
                  _vm.data.viewEndTime &&
                  _vm.data.isAllowViewResult === 1
                    ? _c("span", [_vm._v(" 至 ")])
                    : _vm._e(),
                  _vm.data.viewEndTime && _vm.data.isAllowViewResult === 1
                    ? _c("span", [_vm._v(_vm._s(_vm.data.viewEndTime))])
                    : _vm._e(),
                  !_vm.data.viewStartTime &&
                  _vm.data.viewEndTime &&
                  _vm.data.isAllowViewResult === 1
                    ? _c("span", [_vm._v(" 前")])
                    : _vm._e(),
                  !_vm.data.viewEndTime &&
                  _vm.data.viewStartTime &&
                  _vm.data.isAllowViewResult === 1
                    ? _c("span", [_vm._v(" 后")])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        !_vm.data.viewEndTime &&
                          !_vm.data.viewStartTime &&
                          _vm.data.isAllowViewResult == 0
                          ? "不允许查看答题详情"
                          : ""
                      )
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        !_vm.data.viewEndTime &&
                          !_vm.data.viewStartTime &&
                          _vm.data.isAllowViewResult == 1
                          ? "不限时"
                          : ""
                      )
                    )
                  ])
                ])
              ]),
              _vm.list.length !== 0
                ? _c("li", [
                    _c("h5", [_vm._v("考试记录：")]),
                    _c(
                      "ul",
                      { staticClass: "record" },
                      _vm._l(_vm.list, function(e) {
                        return _c("li", { key: e.id }, [
                          _c("i", [_vm._v(_vm._s(e.submitTime))]),
                          _c(
                            "span",
                            {
                              style: { color: e.isPass ? "#41C160" : "#CE0015" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  e.status == "Done"
                                    ? e.score.toFixed(1) + "分"
                                    : ""
                                )
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              style: { color: e.isPass ? "#41C160" : "#CE0015" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  e.status == "Done"
                                    ? e.isPass
                                      ? "已通过"
                                      : "未通过"
                                    : ""
                                )
                              )
                            ]
                          ),
                          _c(
                            "em",
                            {
                              style: {
                                color: e.status == "Done" ? "#0066B3" : "#666"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.go(e)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(e.status == "Done" ? "查看" : "批阅中")
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]),
            _vm.isShowCountDown
              ? _c("div", { staticClass: "countDown" }, [
                  _c("h5", [_vm._v("距离开考时间还剩")]),
                  _c("p", [
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.countTime && _vm.countTime.split(":")[0])
                      )
                    ]),
                    _vm._v("天 "),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.countTime && _vm.countTime.split(":")[1])
                      )
                    ]),
                    _vm._v("时 "),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.countTime && _vm.countTime.split(":")[2])
                      )
                    ]),
                    _vm._v("分 "),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.countTime && _vm.countTime.split(":")[3])
                      )
                    ]),
                    _vm._v("秒 ")
                  ])
                ])
              : _vm._e(),
            _c(
              "button",
              {
                attrs: { disabled: _vm.disabledBtn },
                on: { click: _vm.getCheckmanage }
              },
              [_vm._v(_vm._s(_vm.LBtnInfo))]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }