import { render, staticRenderFns } from "./introduce.vue?vue&type=template&id=7ba20910&scoped=true&"
import script from "./introduce.vue?vue&type=script&lang=js&"
export * from "./introduce.vue?vue&type=script&lang=js&"
import style0 from "./introduce.vue?vue&type=style&index=0&id=7ba20910&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ba20910",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workplace\\vueProject\\edu_aim\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ba20910')) {
      api.createRecord('7ba20910', component.options)
    } else {
      api.reload('7ba20910', component.options)
    }
    module.hot.accept("./introduce.vue?vue&type=template&id=7ba20910&scoped=true&", function () {
      api.rerender('7ba20910', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/exam/introduce.vue"
export default component.exports