<template>
  <div class="introduce">
    <van-loading size="18" color="#1989fa" v-if="showLoading" style="text-align: center" />
    <template v-else>
      <h4>{{ data.arrangeName }}</h4>
      <ul class="list">
        <li>
          <i></i>
          <span>题目数量</span>
          <p>
            <em>{{ data.totalQuestionQty }}</em>道
          </p>
        </li>
        <li>
          <i></i>
          <span>{{ examTimeText }}</span>
          <p>
            <em>{{ examTime }}</em>
          </p>
        </li>
        <li>
          <i></i>
          <span>卷面总分</span>
          <p>
            <em>{{ data.maxScore && data.maxScore.toFixed(1) }}</em>分
          </p>
        </li>
        <li>
          <i></i>
          <span>考试次数</span>
          <p>
            <em>{{ useRate }}</em>
          </p>
        </li>
      </ul>
      <ul class="info">
        <li>
          <h5>考试说明：</h5>
          <p>
            {{ data.description }}
          </p>
        </li>
        <li>
          <h5>考试目标：</h5>
          <p>
            {{ data.examGoal }}
          </p>
        </li>
        <li>
          <h5>查看结果：</h5>
          <p>
            <span v-if="data.viewStartTime && data.isAllowViewResult === 1">{{
              data.viewStartTime
            }}</span>
            <span v-if="data.viewStartTime && data.viewEndTime && data.isAllowViewResult === 1">
              至
            </span>
            <span v-if="data.viewEndTime && data.isAllowViewResult === 1">{{
              data.viewEndTime
            }}</span>
            <span v-if="!data.viewStartTime && data.viewEndTime && data.isAllowViewResult === 1">
              前</span>
            <span v-if="!data.viewEndTime && data.viewStartTime && data.isAllowViewResult === 1">
              后</span>
            <span>{{
              !data.viewEndTime && !data.viewStartTime && data.isAllowViewResult == 0
                ? "不允许查看答题详情"
                : ""
            }}</span>
            <span>{{
              !data.viewEndTime && !data.viewStartTime && data.isAllowViewResult == 1
                ? "不限时"
                : ""
            }}</span>
          </p>
        </li>
        <li v-if="list.length !== 0">
          <h5>考试记录：</h5>
          <ul class="record">
            <li v-for="e in list" :key="e.id">
              <i>{{ e.submitTime }}</i>
              <span :style="{ color: e.isPass ? '#41C160' : '#CE0015' }">{{
                e.status == "Done" ? e.score.toFixed(1) + "分" : ""
              }}</span>
              <span :style="{ color: e.isPass ? '#41C160' : '#CE0015' }">{{
                e.status == "Done" ? (e.isPass ? "已通过" : "未通过") : ""
              }}</span>
              <em :style="{ color: e.status == 'Done' ? '#0066B3' : '#666' }" @click="go(e)">{{
                e.status == "Done" ? "查看" : "批阅中"
              }}</em>
            </li>
          </ul>
        </li>
      </ul>
      <div v-if="isShowCountDown" class="countDown">
        <h5>距离开考时间还剩</h5>
        <p>
          <em>{{ countTime && countTime.split(":")[0] }}</em>天 <em>{{ countTime && countTime.split(":")[1] }}</em>时 <em>{{ countTime && countTime.split(":")[2] }}</em>分 <em>{{ countTime && countTime.split(":")[3] }}</em>秒
        </p>
      </div>
      <button @click="getCheckmanage" :disabled="disabledBtn">{{ LBtnInfo }}</button>
    </template>
  </div>
</template>

<script>
import { Divider, Loading, Dialog } from "vant";
import { preview, submittedList, checkmanage, getAuth } from "../../api/exam.api.js";
import dayjs from "dayjs";
import { DateFormat } from "../../utils/index.js";

export default {
  name: "introduce",
  components: {
    "van-divider": Divider,
    "van-loading": Loading,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      showLoading: false,
      arrangeId: null,
      userExamMapId: null,
      data: {},
      list: [],
      LBtnInfo: null,
      disabledBtn: false,
      isShowCountDown: false,
      examTimeText: "考试时间",
      countTime: null, //倒计时剩余时间
      timer: "" //倒计时
    };
  },
  computed: {
    // 考试时限
    examTime () {
      if (this.data.isControlSameTime === 0 && this.data.isControlTime === 1) {
        if (this.data.duration === 0) {
          return "不限";
        } else {
          return this.data.duration + "分钟";
        }
      } else if (this.data.isControlSameTime === 1 && this.data.isControlTime === 0) {
        if (this.data.controlSubmitTime === null || this.data.controlSubmitTime === "") {
          return "不限";
        } else {
          this.examTimeText = "限时提交";
          return dayjs(this.data.controlSubmitTime).format("MM-DD HH:mm");
        }
      } else if (this.data.isControlSameTime === 1 && this.data.isControlTime === 1) {
        // 如果当前考试既限定了考试时长，又控制了统一交卷时间，则优先显示最接近的时间控制
        if (this.data.controlSubmitTime === null || this.data.controlSubmitTime === "") {
          return this.data.duration + "分钟";
        } else {
          let sameSubmitTime = new Date(DateFormat(this.data.controlSubmitTime));
          let now = new Date();
          let newDate = new Date(now.getTime() + this.data.duration * 60 * 1000);
          if (newDate >= sameSubmitTime && sameSubmitTime > now) {
            this.examTimeText = "限时提交";
            return dayjs(this.data.controlSubmitTime).format("MM-DD HH:mm");
          } else {
            return this.data.duration + "分钟";
          }
        }
      } else {
        return "不限";
      }
    },
    // 已考次数/设定重复次数
    useRate () {
      if (!this.data.isAllowRepeat) {
        // 不允许重复考
        let usedTimes = 0;
        if (this.data.lastStatus !== "NotStarted") {
          usedTimes = 1;
        }
        return `${usedTimes}/1次`;
      } else if (this.data.examTimes === 0) {
        // 不限次数
        this.times = "";
        return "不限";
      } else {
        return `${this.data.usedExamTimes}/${this.data.examTimes}次`;
      }
    }
  },
  /*   beforeRouteEnter(to, from, next) {
    console.log("to----", to.query.arrangeId);
    let id = to.query.arrangeId;
    getAuth(id)
      .then(res => {
        if (res.code === 200) {
          next(vn => {
            console.log("res-------", res);
          });
        }
      })
      .catch(err => {
        console.log("err-------", err);
      });
  }, */
  created () {
    this.arrangeId = this.$route.query.arrangeId;
    if (window.getLocalStorage("userIdEntityType") == 4) {
      this.showLoading = true;
      let unionId = window.getLocalStorage("unionId");
      try {
        window.localStorage.clear();
        window.sessionStorage.clear();
      } catch (e) {
        window.clearCookieForKey();
      }
      let rurl = window.location.href
      window.setLocalStorage('returnUrl', rurl)
      window.setLocalStorage("unionId", unionId);
      let url = window.location.origin + '/#/login?bindwx=1'
      window.location.replace(url)
    } else {
      this.showLoading = false;
      this.getList();
    }
  },
  mounted () {

  },
  methods: {
    getList () {
      this.showLoading = true;
      preview(this.arrangeId)
        .then(res => {
          this.showLoading = false;
          this.data = res;
          this.userExamMapId = res.userExamMapId;
          if (res.isCanAnswer === 0) {
            if (this.data.errorKey == "apis.ote.arrangement.Expired") {
              this.LBtnInfo = "考试安排已过期";
            } else if (this.data.errorKey == "apis.ote.arrangement.Ended") {
              this.LBtnInfo = "考试安排已结束";
            } else if (this.data.errorKey == "apis.ote.user.exam.validation.TimesLimited") {
              this.LBtnInfo = "考试次数已用完";
            } else if (this.data.errorKey == "apis.ote.arrangement.NotFound") {
              this.LBtnInfo = "考试安排不存在";
            } else if (this.data.errorKey == "apis.ote.examinee.NotFound") {
              this.LBtnInfo = "您不在考试安排的名单内，无法参加本次考试";
            } else {
              this.LBtnInfo = res.errorKey;
            }
            this.disabledBtn = true;
          } else {
            if (
              res.lastStatus === "null" ||
              res.lastStatus === "" ||
              res.lastStatus === "NotStarted"
            ) {
              this.LBtnInfo = "开始考试";
              if (res.isResit === 1) {
                // 进入补考
                this.LBtnInfo = "进入补考";
              }
            } else if (res.lastStatus === "Evaluating") {
              this.LBtnInfo = "继续考试";
            } else {
              if (res.isAllowRepeat) {
                this.LBtnInfo = "再次考试";
                if (res.examTimes === res.usedExamTimes) {
                  if (res.lastStatus === "Marking") {
                    this.LBtnInfo = "批阅中";
                  } else if (res.lastStatus === "Submited") {
                    this.LBtnInfo = "已提交";
                  } else if (res.lastStatus === "Done") {
                    this.LBtnInfo = "已完成";
                  }
                }
              } else {
                if (res.examTimes === res.usedExamTimes) {
                  if (res.lastStatus === "Marking") {
                    this.LBtnInfo = "批阅中";
                  } else if (res.lastStatus === "Submited") {
                    this.LBtnInfo = "已提交";
                  } else if (res.lastStatus === "Done") {
                    this.LBtnInfo = "已完成";
                  }
                }
              }
            }
            // 统一交卷时间控制
            if (res.isControlSameTime && res.controlSubmitTime !== null) {
              if (new Date(DateFormat(res.controlSubmitTime)) <= new Date()) {
                this.LBtnInfo = "已过期";
                this.showGoExam = false;
              }
            }
          }
          if (this.data.isShowScore) {
            //0.不允许查看考试历史  1.可以查看
            this.getSubmittedList();
          }
          // 考试入场时间倒计时控制
          if (res.isControlDate === 1) {
            let now = dayjs(dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"));
            let startDate = dayjs(res.startDate);
            let endDate = dayjs(res.endDate);
            if (startDate - now > 0) {
              // 开始时间未到，倒计时处理
              this.isShowCountDown = true;
              this.disabledBtn = true;
              let t = startDate.diff(now); //距离开始时间的时间戳
              this.countTimeToStart(t);
            } else if (endDate - now < 0) {
              //进入考试的结束时间已过
              this.LBtnInfo = "已过期";
              this.disabledBtn = true;
            }
          }

        })
        .catch(err => { });
    },
    countTimeToStart (startTime) {
      let totalSecond = Math.floor(startTime / 1000);
      let day = Math.floor(totalSecond / (60 * 60 * 24));
      let hour = Math.floor((totalSecond - day * 24 * 60 * 60) / (60 * 60));
      let minute = Math.floor((totalSecond - hour * 60 * 60 - day * 24 * 60 * 60) / 60);
      let second = totalSecond - minute * 60 - hour * 60 * 60 - day * 24 * 60 * 60;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;
      this.timer = window.setInterval(() => {
        if (second >= 1) {
          second = second - 1 > 9 ? second - 1 : "0" + (second - 1);
        } else {
          second = "59";
          if (minute >= 1) {
            minute = minute - 1 > 9 ? minute - 1 : "0" + (minute - 1);
          } else {
            minute = "59";
            if (hour >= 1) {
              hour = hour - 1 > 9 ? hour - 1 : "0" + (hour - 1);
            } else {
              minute = "00";
              hour = "00";
            }
          }
        }
        this.countTime = day + ":" + hour + ":" + minute + ":" + second;
        if (parseInt(hour) === 0 && parseInt(minute) === 0 && parseInt(second) === 0) {
          window.clearInterval(this.timer);
          this.disabledBtn = false;
        }
      }, 1000);
    },
    getSubmittedList () {
      submittedList(this.arrangeId, this.userExamMapId)
        .then(res => {
          this.list = res;
        })
        .catch(err => { });
    },
    getCheckmanage () {
      let data = {
        appendCopy: 0,
        id: this.arrangeId,
        isDuplicateExaminee: 0,
        isExportAll: 0,
        orderByField: "",
        orderType: ""
      };
      checkmanage(data)
        .then(res => {
          if (res.key == 200) {
            this.goExam();
          } else if (res.key == "-1") {
            let msg = `<p>${res.message}<p><p style="margin-top:10px;color:red;">在此期间您的考试可能会受到影响，请确认是否继续考试？</p>`;
            Dialog.confirm({
              title: "温馨提示",
              message: msg
            })
              .then(() => {
                this.goExam();
              })
              .catch(() => { });
          }
        })
        .catch(err => {
          console.log("err----", err);
        });
    },
    go (e) {
      //查看试卷统计分析
      if (e.status === "Done") {
        // 跳转页面
        this.$router.push({
          path: "/exam/examStatistics",
          query: { userExamId: e.id, arrangeId: this.arrangeId, userExamMapId: this.userExamMapId }
        });
      }
    },
    goExam () {
      this.$router.push({
        path: "/exam/questionsList",
        query: { arrangeId: this.arrangeId, userExamMapId: this.userExamMapId }
      });
    }
  },
  destroyed () {
    window.clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
.introduce {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /deep/.van-divider {
    font-size: 24px;
    color: @color3;
    border-color: #d8d8d8;
  }
  h4 {
    font-size: 32px;
    color: @color3;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 30px;
    padding-top: 50px;
    line-height: 50px;
  }
  .list {
    display: flex;
    margin-bottom: 40px;
    li {
      flex: 1;
      text-align: center;
      i {
        width: 86px;
        height: 86px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 auto;
      }
      &:nth-child(1) {
        i {
          background: url("../../assets/icon1.png");
          background-size: 100%;
        }
      }
      &:nth-child(2) {
        i {
          background: url("../../assets/icon2.png");
          background-size: 100%;
        }
      }
      &:nth-child(3) {
        i {
          background: url("../../assets/icon3.png");
          background-size: 100%;
        }
      }
      &:nth-child(4) {
        i {
          background: url("../../assets/icon4.png");
          background-size: 100%;
        }
      }
      span {
        font-size: 22px;
        color: #999999;
        display: block;
      }
      p {
        font-size: 28px;
        color: #151515;
        margin-top: 20px;
        em {
          font-size: 28px;
          color: @blueTheme;
        }
      }
    }
  }
  .info {
    flex: 1;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 20px 30px 0 30px;
    li {
      margin-bottom: 40px;
      h5 {
        font-size: 28px;
        color: @color3;
        margin-bottom: 20px;
      }
      p {
        font-size: 28px;
        color: @color6;
        line-height: 44px;
      }
    }
  }
  button {
    border: none;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 40px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-size: 28px;
    background: @blueTheme;
    outline: none;
    border-radius: 30px;
    &:disabled {
      background: #98c8ec;
    }
  }
  .record {
    color: @color6;
    font-size: 28px;
    li {
      display: flex;
      justify-content: space-between;
      text-align: center;
      border-bottom: 1px solid #d3cfcf;
      padding: 40px 0;
      margin: 0;
      &:last-child {
        border: none;
      }
      span {
        flex: 1;
      }
      em {
        flex: 1;
      }
    }
  }
  .countDown {
    margin: 20px auto;
    h5 {
      font-size: 32px;
      color: @blueTheme;
      margin-bottom: 30px;
      text-align: center;
      font-weight: 400;
    }
    p {
      font-size: 20px;
      color: @color3;
      em {
        display: inline-block;
        width: 50px;
        font-size: 40px;
        color: @blueTheme;
        margin: 0 20px;
      }
    }
  }
}
</style>
